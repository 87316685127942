*{ box-sizing: border-box; line-height: normal; }

body { margin: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #023a6c;
  color: #fff;
  text-align: center;
  z-index: 9999;
}

.cookie-consent-banner-button {
  background-color: #ffcc00;
  color: #023a6c;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 5px;
}

.player-wrapper {

}

.react-player {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100% !important;
  height: 100% !important;

  video {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
  }
}

.screen-1 { max-width: 1100px; margin: 0 auto; }

.swiper-button-next,.swiper-button-prev {color: #ffffff !important; }

@media (min-width: 1180px) { .screen-1 { max-width: 1150px; } }
@media (min-width: 1500px) { .screen-1 { max-width: 1400px; } }

@media (max-width: 1100px) { .screen-1 { padding: 0 70px; } }

@media (max-width: 640px) { .screen-1 { padding: 0 25px; } }



