@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "GothamBook";
    font-weight: normal;
    src: url("../fonts/Gotham/gothambook-webfont.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "GothamBold";
    font-weight: 700;
    src: url("../fonts/Gotham/gothambold-webfont.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "GothamMedium";
    font-weight: 400;
    src: url("../fonts/Gotham/gothammedium-webfont.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "GothamBlack";
    src: url("../fonts/Gotham/gotham-black-webfont.ttf") format("truetype");
    font-display: swap;
  }

  body {
    @apply scroll-smooth overflow-x-hidden font-gotham-book;
  }
}

@layer components {
  .shape-nav {
    background: url("../images/homescreen/normal-shape.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 29%;
    transition: all 0.4s ease-in;
  }
  .shape-nav-hover {
    background: url("../images/homescreen/hover-shape.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 29%;
    height: 100%;
  }
  .sublist-hvr .sublist {
    position: absolute;
    right: 0px;
    top: 95px;
    min-width: 90%;
    width: 200px;
    border-top-right-radius: 35px;
    background-color: #ffffff;
    border: 1px solid #023a6c;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .sublist-hvr:hover .sublist {
    visibility: visible;
    opacity: 1;
  }
  .sublist-hvr:hover .arrow-hvr {
    transform: rotate(90deg);
  }
  .sublist-hvr .arrow-hvr {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in;
  }
  .sublist-hvr .sublist:last-child {
    border-bottom-left-radius: 35px;
  }
  .sublist ul li:first-child,
  .dd-market ul li:first-child {
    border-top-right-radius: 35px;
  }
  .sublist ul li:last-child,
  .dd-market ul li:last-child {
    border-bottom-left-radius: 35px;
    border: none;
  }
  .fullscreen {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  .text ul {
    list-style-type: disc;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
  }
  h3 {
    font-weight: bold;
    font-size: 21px;
  }

  @media (max-width: 1536px) {
    .shape-nav {
      width: 25% !important;
    }
  }
  @media (min-width: 1260px) and (max-width: 1360px) {
    .shape-nav {
      width: 23% !important;
    }
  }
  @media (max-width: 1259px) {
    .shape-nav {
      width: 25% !important;
    }
  }

  .popUp-container.active {
    filter: blur(4px);
    pointer-events: none;
  }
  .popUp-container.active::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
  }

  @media (min-width: 300px) and (max-width: 400px) {
    .popUp-xs {
      width: calc(100vw - 50px) !important;
    }
    .contact_form {
      width: 320px;
    }
  }
  @media (max-width: 330px) {
    .popUp-xs {
      width: 300px !important;
    }
    .contact_form {
      width: 270px;
    }
  }

  .card-product:hover {
    background-color: #ffcc00;
  }
  .card-product:hover > div:nth-last-child(1) {
    border-color: #ffffff;
  }
  .shape-2 {
    background: url("../images/homescreen/shape3.svg");
    background-attachment: inherit;
    transform: translateY(0);
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    background-size: 100%;
    transition: transform 0.4s ease, background 0.4s ease;
  }
  .shape-2:hover {
    background: url("../images/homescreen/shape-3-hover.svg");
    background-attachment: inherit;
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100%;
    transform: translateY(50px);
  }
  .testimonial-brackets::before {
    content: "";
    display: inline-block;
    background: url("../images/testimonial-left.webp") no-repeat;
    width: 200px;
    height: 100px;
  }
  .custom-list ul {
    list-style: none;
  }
  .custom-list ul li::before {
    content: "";
    display: inline-block;
    width: 10px; /* Adjust the width as needed */
    height: 10px; /* Adjust the height as needed */
    background-color: #ffcc00; /* Adjust the color as needed */
    border-radius: 50%; /* Use border-radius to create a circular shape */
    margin-right: 10px;
    margin-top: 5px;
  }
  .policy-text.custom-list ul li {
    display: flex;
    margin: 10px 0;
  }
  .policy-text.custom-list ul li::before {
    flex-shrink: 0;
  }
  .policy-text.custom-list ul li strong {
    display: contents;
  }
  .header-section-pad {
    padding: 4rem 1rem;
  }
  .header-section-pad-2 {
    padding: 9rem 1rem;
    padding-left: clamp(0px, 17.99vw, 345px);
  }

  .hyperlink a,
  .custom-list a {
    color: #023a6c;
    transition: color 0.4s ease-in;
  }
  .hyperlink a:hover,
  .custom-list a:hover {
    color: #ffcc00;
  }

  .testimonialSwiper.swiper-container {
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .testimonialSwiper.swiper {
    overflow: visible !important;
  }
  .testimonialSwiper.swiper
    .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: -21.5% !important;
    bottom: -16% !important;
  }
  .testimonialSwiper.swiper .swiper-pagination .swiper-pagination-bullet {
    background: rgba(2, 58, 108, 0.38) !important;
    width: 10px !important;
    height: 10px !important;
    opacity: inherit;
    transition: width 0.4s ease;
  }
  .testimonialSwiper.swiper
    .swiper-pagination
    .swiper-pagination-bullet-active {
    background: #023a6c !important;
    width: 18px !important;
    border-radius: 5px;
  }
  .testimonialSwiper.swiper .swiper-slide.swiper-slide-prev,
  .testimonialSwiper.swiper .swiper-slide.swiper-slide-next {
    visibility: hidden !important;
  }
  .testimonialSwiper.swiper .swiper-slide:not(.swiper-slide-active) {
    visibility: hidden !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #ffffff !important;
  }

  @media (max-width: 1536px) {
    .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
      left: -27.5% !important;
    }
  }
  @media (max-width: 1360px) {
    .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
      left: -27% !important;
    }
  }
  @media (max-width: 1024px) {
    .swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
      left: -2% !important;
      bottom: -10% !important;
    }
  }

  input::placeholder {
    color: #023a6c;
    font-style: italic;
    opacity: 0.4;
    font-family: Arial, sans-serif;
    font-weight: normal;
  }
  .input {
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  input.input::placeholder {
    color: white !important;
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.25rem;
    opacity: 1;
    font-family: Arial, sans-serif;
    font-weight: normal;
  }

  .background-shape {
    background: url("../images/homescreen/shape.webp");
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
  }
  .contact-form-pad {
    padding: 5rem 0;
  }

  @media (max-width: 1364px) {
    .contact-form-pad {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @media (max-width: 1100px) {
    .header-section-pad,
    .header-section-pad-2 {
      padding: 30px 70px;
    }
  }
  @media (max-width: 640px) {
    .header-section-pad,
    .header-section-pad-2 {
      padding: 30px 25px;
    }
  }

  .journey .pad-30 {
    padding-left: 63px;
  }
  .leading-none {
    line-height: 1 !important;
  }

  .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 2px solid #ffcc00 !important;
  }

  .form {
    position: relative;
    width: 20rem;
    height: 3rem;
  }
  .form .input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ffcc00;
    font-size: inherit;
    font-weight: 700;
    font-family: inherit;
    color: white;
    outline: none;
    padding: 1.25rem;
    background: none;
    transition: border-bottom 500ms ease-in;
  }
  .form .input:hover,
  .form .input:focus {
    border-bottom: 2px solid #ffcc00;
  }

  .form .label {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    padding: 0 0.5rem;
    color: white;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  }

  .input:focus ~ .label,
  .input:not(:placeholder-shown).input:not(:focus) ~ .label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
    opacity: 0.4;
  }

  .tab .tab-content.active {
    background-color: white;
    color: #023a6c;
    font-weight: 900;
  }
  .tab .tab-content.active:nth-child(1) {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 3px;
  }
  .tab .tab-content.active:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 7px;
  }
  /* .juicer-feed.modern .j-image img {height: 253px !important;} */
  .juicer-feed li.feed-item.j-video .j-image:before {
    content: "" !important;
  }
  @media (max-width: 635px) {
    .tab .tab-content.active:nth-child(1) {
      border-top-left-radius: 7px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0;
    }
    .tab .tab-content.active:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 3px;
    }
  }
  .text-opacity {
    color: rgba(2, 58, 108, 0.25);
  }
  .juicer-feed .juicer-button,
  .juicer-feed .j-text,
  .juicer-feed .referral,
  .juicer-feed .j-poster {
    display: none !important;
  }
  .juicer-feed img {
    width: 100%;
    height: auto !important;
  }
  .juicer-feed ul li.feed-item {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 520px) and (max-width: 639px) {
  .left-xxs {
    left: 34%;
  }
}
@media (max-width: 450px) {
  .left-xxs {
    left: 20%;
  }
  @media (max-width: 400px) {
    .left-xxs {
      left: 10%;
    }
  }

  .css-1xsa8yo .MuiTextField-root {
    margin: 0 !important;
  }
}

.leading-text p {
  line-height: 1.5;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 3s ease;
}

.loading-spinner,
.loading-logo {
  animation: zoomInOut 2s linear infinite;
  -webkit-animation: zoomInOut 2s linear infinite;
}
.loading-screen.hidden {
  opacity: 0;
  pointer-events: none;
}
.heading-title {
  position: "absolute";
  top: "-9999px";
  left: "-9999px";
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.maintenance-container {
  padding-top: 5%;
  color: white;
  /* padding-bottom: 5%; */
  height: 100dvh;
}

.maintenance-text {
  max-width: 2500px;
  text-align: left;
}

@media screen and (max-width: 1280px) {
  .maintenance-text {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 550px) {
  .maintenance-text {
    padding-left: 20%;
    padding-right: 20%;
  }

  .maintenance-container {
    height: 120dvh;
  }

  .maintenance-logo {
    width: 200px;
    height: 90px;
  }

  .maintenance-logo-2 {
    width: 90px;
    height: 80px;
  }
}

.image-container {
  width: 100%;
  padding-bottom: 55.3%;
  overflow: hidden;
  position: relative;
}

.image-container img {
  width: 100%;
  object-fit: contain;
  transition: transform 0.7s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.image-container:hover img {
  transform: scale(1.1);
}

.image-container-2 {
  width: 100%;
  padding-bottom: 98%;
  overflow: hidden;
  position: relative;
}

.image-container-2 img {
  width: 100%;
  object-fit: contain;
  transition: transform 0.7s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.image-container-2:hover img {
  transform: scale(1.1);
}
